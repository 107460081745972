import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Container from "react-bootstrap/Container"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <Container className="mt-5" style={{textAlign: "center"}}>
      <h3>Page Not Found</h3>
      <p className="mt-4">Click <Link style={{color: "#536eb1"}} to="/">here</Link> to return to the home page.</p>
    </Container>
    
  </Layout>
)

export default NotFoundPage
